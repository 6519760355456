import jump, { easeInOutQuad } from 'jump.js'

const handleClick = (hash) => {
  const selector = `#${hash.replace('#anchor-', '')}`
  jump(selector, {
    duration: 500,
    offset: selector == '#body' ? 0 : document.body.classList.contains('is-mobile') ? -60 : -100,
    easing: easeInOutQuad,
  })
}

const hash = location.hash
if (hash.indexOf('#anchor-') !== -1) {
  handleClick(hash)
}

export const initAnchorScroll = () => {
  const $anchorNodes = document.querySelectorAll('a[href^="#anchor-"]')
  const $anchors = Array.prototype.slice.call($anchorNodes, 0);
  $anchors.forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault()
      handleClick(anchor.hash)
    })
  })
}
